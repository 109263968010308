import { match, compile } from "path-to-regexp";

export const useHashToggle = <T extends object>(
  key: { cs: string; en: string } | string,
) => {
  const { locale } = useI18n<{}, "cs" | "en">();
  const hash = typeof key === "string" ? `#${key}` : `#${key[locale.value]}`;

  const router = useRouter();
  const currentHash = computed(() => {
    return router.currentRoute.value.hash;
  });

  const matcher = match<T>(hash, { decode: decodeURIComponent, end: false });
  const compiler = compile<T>(hash, { encode: encodeURIComponent });

  const isOpen = computed(() => {
    return matcher(currentHash.value) !== false;
  });

  const open = async (params?: T) => {
    const newHash = compiler(params);

    return await router.replace({
      hash: newHash,
      query: router.currentRoute.value.query,
    });
  };

  const close = async () => {
    const nextHash = popHash(currentHash.value);

    await router.replace({
      hash: nextHash,
      query: router.currentRoute.value.query,
    });
  };

  const closeAll = async () => {
    await router.replace({
      hash: "",
      query: router.currentRoute.value.query,
    });
  };

  const canGoBack = computed(() => {
    return currentHash.value.split("/").length > 2;
  });

  const params = computed(() => {
    const match = matcher(currentHash.value);
    if (match === false) {
      return {} as T;
    }

    return match.params;
  });

  return {
    isOpen,
    canGoBack,
    open,
    close,
    closeAll,
    params,
  };
};

const popHash = (hash: string) => {
  const parts = hash.split("/");
  parts.pop();

  return parts.join("/");
};
